import { Component, OnInit, AfterContentChecked, ViewChild, OnDestroy, Injectable } from '@angular/core';
import { Location, NgIf, NgFor, NgClass } from '@angular/common';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from '@angular/router';

import { ItemsService } from '../../../shared/services/items.service';
import { TopService } from '../../../shared/services/top.service';
import { LeftCategoriesService } from '../../../shared/services/left-categories.service';

// import { UserSupportComponent } from '../../../shared/user-support/user-support.component';
import { PubSubService } from '../../../core/pubsub.service';
import { TopCategoriesFileSaverService } from './top-categories-file-saver.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { SharedSource } from '../../../core/shared-source';
import { Subscription } from 'rxjs';
import { ITopNav } from '../../interfaces/ITopNav';

import * as models from '../../interfaces/model';
import { INav } from '../../interfaces/model';
import { IJustSoldMessage } from '../../../shared/interfaces/IJustSoldMessage';
import { SharedService } from '../../../shared/shared.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { GuestService } from '../../../shared/services/guest.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { OutletsService } from '../../outlets/outlets.service';
import { ItemFilterService } from '../../../shared/item-filter/item-filter.service';
import { TopFiltersEnum } from '../../../buyer/enums/topFiltersEnum';
import * as _ from 'lodash';
import { SessionService } from '../../../services/session.service';
import { UserService } from '../../../user/user.service';
import { environment } from '../../../../environments/environment';
import { DropdownCategoriesComponent } from '../../categories/dropdown-categories.component';

@Component({
    selector: 'app-top-categories',
    templateUrl: './top-categories.component.html',
    styleUrls: ['./top-categories.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLinkActive, RouterLink, NgClass, DropdownCategoriesComponent]
})
export class TopCategoriesComponent implements OnInit, AfterContentChecked, OnDestroy {
  // @ViewChild(UserSupportComponent) supportComponent: UserSupportComponent;
  topFiltersEnum = TopFiltersEnum;
  attributes: models.Attribute[] = [];
  categories: models.Category[] = [];
  topFilters: ITopNav[];
  navigationTree: ITopNav[];
  isCatalog = false;
  date = new Date();
  fileName = 'HUBX-Price-List-' + this.date.toLocaleString() + '.xlsx';
  isEditOrder = false;
  pubServiceSubscription: Subscription;
  justSoldCount: boolean;
  /*hovered;
  hoveredIndex: number;*/
  navChilds;
  // for single outlet page
  // isOutlet;

  isCategoriesOpened = false;
  visibleCategoryId = null;
  environment = environment;
  /*visibleCategoryPosition = { top: '0px' };*/

  constructor(
    public itemsService: ItemsService,
    public topService: TopService,
    public notificationService: NotificationService,
    private location: Location,
    private pubSubService: PubSubService,
    private leftCategoriesService: LeftCategoriesService,
    public router: Router,
    private topCategoriesFileSaverService: TopCategoriesFileSaverService,
    public sharedService: SharedService,
    private guestService: GuestService,
    public outletsService: OutletsService,
    private activeRoute: ActivatedRoute,
    public sessionService: SessionService,
    public itemFilterService: ItemFilterService,
    public userService: UserService) { }

  ngOnInit() {

    this.pubServiceSubscription = this.pubSubService.sharedSubject.subscribe(myEvent => {
      if (myEvent.name === SharedSource.editOrderMode) {
        this.isEditOrder = myEvent.data;
      }

      if (myEvent.name === SharedSource.topCategory) {
        this.topFilters = myEvent.data;
      }

      if (myEvent.name === SharedSource.IsJustSoldSelected) {
        if (!myEvent.data) {
          this.notificationService.highlight = false;
        }
      }

      if (myEvent.name === SharedSource.JustSoldValues) {
        this.justSoldCount = true;
        this.notificationService.highlight = true;
        setTimeout(() => {
          if (this.itemsService.selectedTopAttribute !== 'Just Sold') {
            this.justSoldCount = false;
            this.notificationService.highlight = false;
          } else {
            this.justSoldCount = false;
          }
        }, 1000);
      }

      if (myEvent.name === SharedSource.manufacturersDataChanged) {
        if (this.itemFilterService.isOutletsSinglePage) {
          const cleanUrl = this.router.url.replace(/\/guest/, '');
          const currentOutlet = cleanUrl.split('/');
          const outletName = currentOutlet[2].replace(/\_/g, ' ');
          // you are in a top category e.g todays_deals
          if (currentOutlet.length === 4) {
            this.outletsService.topcatNavName = currentOutlet[3];
          }
          this.outletsService.getReactiveOutlet(outletName);
        }
      }
    });

    this.loadTopFilterData();
  }

  ngAfterContentChecked() {
    this.isCatalog = this.topService.isCatalog;
  }

  ngOnDestroy() {
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }
  }

  allManufacturerSpecificItems() {
    this.outletsService.topcatNavName = undefined;
    this.itemsService.showFilterComponent = false;
    const transformedName = String(this.outletsService.selectedOutlet.name.replace(/\s/g, '_')).toLowerCase();

    this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/` + transformedName]);
    this.outletsService.resetEverythingButManufacturers();
    this.itemsService.skipManufacturers = false;
    this.itemFilterService.selectedCondition = this.itemsService.conditionAttributeValues.find(c => c.value === 'All Conditions');
    this.itemsService.paginationInfo = null;
    this.itemsService.setSelectedManufacturer(this.outletsService.selectedOutlet.id, true, true);
  }

  getPriceList(): void {
    this.topService.loading = true;
    this.topCategoriesFileSaverService.downloadPriceList().subscribe(blob => {
      this.topService.loading = false;
      importedSaveAs(blob, this.fileName);
    });
  }

  // Check if Tp exists before calling
  loadTopFilterData(): void {
    this.topFilters = this.topService.topFilters;
  }

  goBack(): void {
    this.location.back();
  }

  selectJustSold() {
    this.itemsService.selectJustSold();
  }

  closeOutlet() {
    this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`]);
  }

  goToEditMode(): void {
    this.isEditOrder ?
      this.router.navigate(['cart'], { queryParams: { updatingOrder: 'Y' } }) : this.router.navigate(['cart']);
  }

  checkForCartPage(): boolean {
    return this.router.url === '/orders';
  }

  // getSupport(): void {
  //   this.supportComponent.showModalTrigger = true;
  //   this.supportComponent.animateIn = true;
  // }

  isEditMode(): boolean {
    return !this.isCatalog && this.isEditOrder;
  }

  isTopFilterEqualToNavName(topFilter: INav, navName: string): boolean {
    return topFilter.navName === navName;
  }

  toggleCategories(isVisible: boolean): void {
    this.isCategoriesOpened = isVisible;
  }

}
