import { enableProdMode, Injectable, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { MyHammerConfig } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { SharedModule } from './app/shared/shared.module';
import { StaticModule } from './app/static/static.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BuyerModule } from './app/buyer/buyer.module';
import { VendorModule } from './app/vendor/vendor.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { Angulartics2Module } from 'angulartics2';
import { ROUTES } from './app/app.routes';
import { provideRouter } from '@angular/router';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UserModule } from './app/user/user.module';
import { CoreModule } from './app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderService } from './app/shared/services/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { EmailService } from './app/services/email.service';
import { GoogleAnalyticsService } from './app/services/google-analytics.service';
import { PageErrorService } from './app/page-error/page-error.service';
import { SharedService } from './app/shared/shared.service';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, Title, BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthInterceptor } from './app/auth/auth.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { ResetPasswordService } from './app/shared/services/reset-password.service';
import { GlobalSearchService } from './app/services/search.service';
import { SessionService } from './app/services/session.service';
import { CurrencyPipe, CommonModule } from '@angular/common';
import { AuthGuardService } from './app/auth/auth-guard.service';
import { AuthService } from './app/auth/auth.service';

if (environment.production) {
  enableProdMode();
  const scriptEl = window.document.createElement('script');
  scriptEl.src = '//script.crazyegg.com/pages/scripts/0106/3438.js';
  window.document.body.appendChild(scriptEl);
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, CommonModule, CoreModule, UserModule, InfiniteScrollModule, 
        // Ng5SliderModule,
        NgxSliderModule, Angulartics2Module.forRoot(), HighchartsChartModule, VendorModule, BuyerModule, TooltipModule.forRoot(), BsDropdownModule.forRoot(), ReactiveFormsModule, StaticModule, SharedModule, GoogleTagManagerModule.forRoot({
            id: environment.gtmId,
        })),
        AuthService,
        AuthGuardService,
        CurrencyPipe,
        SessionService,
        GlobalSearchService,
        ResetPasswordService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        Title,
        SharedService,
        PageErrorService,
        GoogleAnalyticsService,
        EmailService,
        CookieService,
        LoaderService,
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(ROUTES),
        provideAnimations()
    ]
})
  .catch(err => console.log(err));
