import { Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as _ from 'lodash';
import 'moment-timezone/builds/moment-timezone-with-data';
import { Observable, Subscription, of } from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import { UserService } from '../../user.service';
import { OfferService } from '../offer.service';
import { GenericCountdownSourceEnum } from './../../../buyer/enums/GenericCountdownSourceEnum';
import { IItem } from './../../../buyer/interfaces/IItem';
import { IOfferError } from './../../../buyer/interfaces/IOfferError';
import { PubSubService } from './../../../core/pubsub.service';
import { SharedSource } from './../../../core/shared-source';
import { SessionService } from './../../../services/session.service';
import { OfferErrorCodeMap } from './../../../shared/classes/offer-error-code-map';
import {SafeEventEmitter} from './../../../shared/classes/safeEventEmitter';
import * as enums from './../../../shared/enums/enum';
import * as models from './../../../shared/interfaces/model';
import { ItemsService } from './../../../shared/services/items.service';
import { TopService } from './../../../shared/services/top.service';
import { SharedService } from './../../../shared/shared.service';
import { VendorService } from './../../../vendor/vendor.service';
import { OfferErrorCodes } from './../../../shared/enums/offer-error-codes';
import { OrdersService } from './../../../buyer/order-history/orders/orders.service';
import { CustomOfferCurrencyPipe } from '../../../shared/pipes/offer-currency.pipe';
import { UtcToNowOffersPipe } from '../../../shared/pipes/utc-to-now-offers.pipe';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { OnlyDigitsDirective } from '../../../shared/directives/only-digits.directive';
import { NgxMaskModule } from 'ngx-mask';
import { GenericCountdownComponent } from '../../../shared/generic-countdown/generic-countdown.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, DecimalPipe, PercentPipe } from '@angular/common';


@Component({
    selector: 'app-offer-chat-message',
    templateUrl: './offer-chat-message.component.html',
    styleUrls: ['./offer-chat-message.component.scss', '../../../shared/confirm-modal/confirm-modal.component.scss'],
    standalone: true,
    imports: [NgIf, GenericCountdownComponent, FormsModule, ReactiveFormsModule, NgxMaskModule, OnlyDigitsDirective, NgClickOutsideDirective, NgFor, NgSwitch, NgSwitchCase, DecimalPipe, PercentPipe, UtcToNowOffersPipe, CustomOfferCurrencyPipe]
})
export class OfferChatMessageComponent implements OnInit, OnDestroy {
  @ViewChild('offerChatMessage') private _chatElementRef!: ElementRef;
  offerEvent: models.OfferEvent;
  previousEvent: models.OfferEvent;
  displayCounterOfferForm = false;
  isCounterpartyPriorOffer: boolean;
  isLastEvent: boolean;
  isTyping = false;
  displayStatus: boolean = true;
  isBuyer: boolean;
  isVendor: boolean;
  isSpecialRole: boolean;
  @Output() editOffer: EventEmitter<models.OfferEvent> = new EventEmitter<models.OfferEvent>();
  @Output() editedOffer: SafeEventEmitter<models.OfferEventUpdateResponse> = new SafeEventEmitter<models.OfferEventUpdateResponse>();
  @Output() counterOffer: EventEmitter<models.OfferEvent> = new EventEmitter<models.OfferEvent>();
  @Output() backCounterOffer: EventEmitter<number> = new EventEmitter<number>();
  @Output() counteredOffer: SafeEventEmitter<models.CounterOfferResponse> = new SafeEventEmitter<models.CounterOfferResponse>();
  @Output() routeToOrderDetails: EventEmitter<number> = new EventEmitter<number>();
  offerEventEdited = false;
  previousOfferWasAccepted = false;
  offerWasAccepted = false;
  offerWasRejected = false;
  offerExpired = false;
  orderWasCreated = false;
  offerHasExpired = false;
  offerWasCancelled = false;
  FullOfferStatusEnum = enums.FullOfferStatusEnum;
  previousAcceptedIndex = null;

  userId = '';
  offerEventTypeTitle = new Map();
  offerEventTypeUsernameBuyer = new Map();
  offerEventTypeUserNameVendor = new Map();
  priceOptions: models.PriceOptions[];
  metadata: models.OfferMetadata;
  totalPercentOff: number;
  userMessage: boolean;
  isNew: boolean;
  isRead: boolean;
  hideResponseButtons = false;

  hideCancelButtonOnCounter = false;

  goodForDropdown = {
    disabled: false,
    expanded: false,
    selectedValue: 1,
    valueWasSelectedByUser: true
  };
  priceDropdown = {
    disabled: false,
    expanded: false,
    selectedValue: 0,
    selectedPercent: '0%',
    valueWasSelectedByUser: false
  }
  isFinalCheckBox = false;
  counterOfferError: IOfferError;
  counterOfferForm: FormGroup;
  EventTypesEnum = enums.OfferEventTypes;

  counterOfferErrorResponseMsg = '';
  fromOfferList = false;
  GenericCountdownSourceEnum = GenericCountdownSourceEnum;
  offerCanceledDueToFeatureSwitched = false;

  isEditOffer = false;
  isEditOfferAnimate = false;
  OfferErrorCodes = OfferErrorCodes;

  // Emojis
  lock = '&#128274;';
  ex = '&#10060;';
  watch = '&#9201;';
  check = '&#10004;';
  party = '&#127881;';

  arrowUp = '&#9650;';
  arrowDown = '&#9660;';

  item: models.OfferItemDetail;
  priceBasedonQty = 0;
  validTotalOffer = true;
  formErrors: any;
  subscriptions: Subscription[] = [];
  Math = Math;
  discountApplied = false;
  formErrorMsg_QuantityMoreThanAvailability = 'Your quantity is more than available';
  formErrorMsg_QuantityLessOrEqualToZero = 'Your quantity must be greater than zero';
  formErrorMsg_Quantity = '';
  offerUpdatedMessage = '';

  constructor(private sessionService: SessionService,
    private formBuilder: FormBuilder,
    public offerService: OfferService,
    public userService: UserService,
    private itemsService: ItemsService,
    public topService: TopService,
    private pubSubService: PubSubService,
    private zone: NgZone,
    private sharedService: SharedService,
    private vendorService: VendorService,
    private orderService: OrdersService) {
    this.createCounterOfferForm();
  }

  ngOnInit(): void {
    this.formErrors = this.getDefaultFormErrors();
    this.setUpSubscriptions();
    this.zone.run(() => {
      this.offerExpired = this.offerService.verifyIfOfferExpired(this.offerService.selectedOffer?.expiresOn);
    });

    this.userId = sessionStorage.getItem('user_id');

    this.offerEventEdited = [enums.OfferEventTypes.VendorUpdatedOfferEvent, enums.OfferEventTypes.BuyerUpdatedOfferEvent].includes(this.offerEvent.eventType);
    this.previousOfferWasAccepted = [enums.OfferEventTypes.BuyerAcceptedPreviousOffer, enums.OfferEventTypes.VendorAcceptedPreviousOffer].includes(this.offerEvent.eventType);
    this.offerWasAccepted = [enums.OfferEventTypes.BuyerAcceptedOffer, enums.OfferEventTypes.VendorAcceptedOffer].includes(this.offerEvent.eventType);
    this.offerWasRejected = [enums.OfferEventTypes.BuyerRejectedOffer, enums.OfferEventTypes.VendorRejectedOffer].includes(this.offerEvent.eventType);
    this.orderWasCreated = this.offerEvent.eventType === enums.OfferEventTypes.OrderCreated;
    this.offerHasExpired = [enums.OfferEventTypes.BuyersOfferExpired, enums.OfferEventTypes.VendorsOfferExpired].includes(this.offerEvent.eventType);
    this.offerWasCancelled = [enums.OfferEventTypes.BuyerCancelledOffer, enums.OfferEventTypes.VendorCancelledOffer, enums.OfferEventTypes.SystemCancelledOffer].includes(this.offerEvent.eventType);

    this.setUpEventTypeMaps();
    if (this.isTyping) {
      this.isLastEvent = true;
      return
    };
    this.userMessage = this.turnToTheRight();
    this.setUpdatedOfferMessage();
  }

  ngOnDestroy(): void {
    this.priceDropdown.selectedValue = 0;
    this.goodForDropdown.selectedValue = 0;
    this.counterOfferForm.reset();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpEventTypeMaps() {
    this.offerEventTypeTitle.set(enums.OfferEventTypes.OfferMade, "Offer Made");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.VendorAcceptedOffer, "Offer Accepted");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.VendorCounteroffered, "Counteroffer");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.VendorRejectedOffer, "Offer Rejected");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.BuyerAcceptedOffer, "Offer Accepted");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.BuyerCounteroffered, "Counteroffer");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.BuyerRejectedOffer, "Offer Rejected");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.BuyerCancelledOffer, "Offer Cancelled");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.VendorCancelledOffer, "Offer Cancelled");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.SystemCancelledOffer, "Offer Cancelled");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.BuyersOfferExpired, "Offer Expired");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.VendorsOfferExpired, "Offer Expired");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.OrderCreated, "Order Created");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.VendorAcceptedPreviousOffer, "Offer Accepted");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.BuyerAcceptedPreviousOffer, "Offer Accepted");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.VendorUpdatedOfferEvent, "Counteroffer");
    this.offerEventTypeTitle.set(enums.OfferEventTypes.BuyerUpdatedOfferEvent, "Counteroffer");


    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.OfferMade, "You");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.VendorAcceptedOffer, "Seller");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.VendorCounteroffered, "Seller");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.VendorRejectedOffer, "Seller");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.BuyerAcceptedOffer, "You");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.BuyerCounteroffered, "You");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.BuyerRejectedOffer, "You");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.BuyerCancelledOffer, "You");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.VendorCancelledOffer, "Seller");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.SystemCancelledOffer, "HUBX");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.BuyersOfferExpired, "HUBX");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.VendorsOfferExpired, "HUBX");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.OrderCreated, "HUBX");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.VendorAcceptedPreviousOffer, "Seller");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.BuyerAcceptedPreviousOffer, "You");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.VendorUpdatedOfferEvent, "Seller");
    this.offerEventTypeUsernameBuyer.set(enums.OfferEventTypes.BuyerUpdatedOfferEvent, "You");

    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.OfferMade, "Buyer");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.VendorAcceptedOffer, "You");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.VendorCounteroffered, "You");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.VendorRejectedOffer, "You");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.BuyerAcceptedOffer, "Buyer");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.BuyerCounteroffered, "Buyer");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.BuyerRejectedOffer, "Buyer");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.BuyerCancelledOffer, "Buyer");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.VendorCancelledOffer, "You");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.SystemCancelledOffer, "HUBX");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.BuyersOfferExpired, "HUBX");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.VendorsOfferExpired, "HUBX");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.OrderCreated, "HUBX");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.VendorAcceptedPreviousOffer, "You");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.BuyerAcceptedPreviousOffer, "HUBX");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.VendorUpdatedOfferEvent, "You");
    this.offerEventTypeUserNameVendor.set(enums.OfferEventTypes.BuyerUpdatedOfferEvent, "Buyer");
  }

  turnToTheRight(): boolean {
    if (this.isBuyer)
      return [enums.OfferEventTypes.OfferMade,
      enums.OfferEventTypes.BuyerAcceptedOffer,
      enums.OfferEventTypes.BuyerCancelledOffer,
      enums.OfferEventTypes.BuyerCounteroffered,
      enums.OfferEventTypes.BuyerRejectedOffer,
      enums.OfferEventTypes.BuyerAcceptedPreviousOffer,
      enums.OfferEventTypes.BuyerUpdatedOfferEvent]
        .includes(this.offerEvent.eventType);
    return [enums.OfferEventTypes.VendorAcceptedOffer,
    enums.OfferEventTypes.VendorCancelledOffer,
    enums.OfferEventTypes.VendorCounteroffered,
    enums.OfferEventTypes.VendorRejectedOffer,
    enums.OfferEventTypes.VendorAcceptedPreviousOffer,
    enums.OfferEventTypes.VendorUpdatedOfferEvent]
      .includes(this.offerEvent.eventType);
  }

  newCounterOffer() {
    this.createCounterOfferForm();
  }

  createCounterOfferForm() {
    this.counterOfferForm = this.formBuilder.group({
      quantity: [null, [Validators.required]],
      price: [null, [Validators.required, this.validatePrice.bind(this)]],
    });
    const quantityControl = this.counterOfferForm.get('quantity');
    this.subscriptions.push(
      quantityControl.valueChanges.pipe(
        debounceTime(350),
        distinctUntilChanged()
      ).subscribe(value => {
        this.validateQuantity(quantityControl);
      })
    );
  }

  onCounterOffer() {
    if (this.topService.loading || this.isTyping || this.offerWasAccepted || this.offerWasRejected || this.offerService.disableCounterofferFormActions) {
      return;
    }

    if (this.offerExpired) {
      this.offerService.offerExpiredSettings.show = true;
      return;
    }
    this.hideResponseButtons = true;
    this.hideCancelButtonOnCounter = true;

    this.counterOffer.emit(this.offerEvent);
  }

  onBackCounterOffer() {
    if (this.isEditOffer) {
      this.counterOfferForm.patchValue({ quantity: null });
      this.counterOfferForm.patchValue({ price: null });

      this.priceDropdown.selectedValue = null;
      this.goodForDropdown.selectedValue = null;
      this.counterOfferForm.reset();

      this.isEditOffer = false;
      setTimeout(() => {
        this.isEditOfferAnimate = false;
      }, 300);

      return;
    }
    this.backCounterOffer.emit(1);
  }

  toggleGoodForDropdown() {
    if (this.goodForDropdown.disabled) {
      return;
    }
    this.goodForDropdown.expanded = !this.goodForDropdown.expanded;
  }

  onSelectDuration(durationInDays: number, e: Event) {
    if (this.goodForDropdown.disabled && e) {
      e.stopPropagation();
      return;
    }

    if (durationInDays > 1 && this.rejectGoodForDropdownOption(durationInDays)) {
      return;
    }

    this.goodForDropdown.valueWasSelectedByUser = true;
    this.goodForDropdown.selectedValue = durationInDays;
  }

  togglePriceDropdown() {
    if (this.priceDropdown.disabled) {
      return;
    }
    this.priceDropdown.expanded = !this.priceDropdown.expanded;
  }

  onSelectPrice(price: number, percent: string, e: Event) {
    if (this.priceDropdown.disabled && e) {
      e.stopPropagation();
      return;
    }

    this.priceDropdown.selectedValue = price;
    this.priceDropdown.selectedPercent = percent;
    this.priceDropdown.valueWasSelectedByUser = true;

    price = Math.ceil(price * 100) / 100;

    this.counterOfferForm.patchValue({ price: price })
  }

  onIsFinalCheckBoxChanged(event: any) {
    this.isFinalCheckBox = event.target.checked;
  }

  submitCounterOffer(scope?) {
    var that = this;
    if (scope) {
      that = scope;
    }

    if (!that.validCounterofferForm())
      return;

    if (!that.offerService.confirmCounterofferSettings.show) {
      that.offerService.submitCounterofferReference = {
        fn: that.submitCounterOffer,
        scope: that
      };
      that.offerService.confirmCounterofferSettings.show = true;
      return;
    }

    that.isFinalCheckBox = that.offerService.isFinalCounteroffer(that.priceDropdown.selectedValue, that.isBuyer, that.discountApplied);
    const counterOfferPayload: models.CounterOfferRequest = {
      instanceId: sessionStorage.getItem('sessionId'),
      quantity: that.counterOfferForm.get('quantity').value,
      price: that.priceDropdown.selectedValue,
      isFinal: that.isFinalCheckBox,
      goodFor: that.goodForDropdown.selectedValue,
      effectivePrice: that.offerService.selectedOffer.effectivePrice,
      version: that.offerService.selectedOffer.version
    };
    that.offerService.counterOffer(that.offerService.selectedOffer.id, counterOfferPayload)
      .subscribe
      ((res) => {
        that.topService.loading = false;
        that.counteredOffer.tryEmit(res);
        that.offerService.confirmCounterofferSettings.show = false;
      },
        (err) => {
          that.topService.loading = false;
          that.offerService.confirmCounterofferSettings.show = false;
          if (err.status === that.offerService.OFFER_VALIDATION_ERROR_STATUS_CODE || err.status === that.offerService.PRECONDITION_FAILED) {
            that.offerService.confirmCounterofferErrorSettings.headerText = OfferErrorCodeMap.ErrorCodeMap[err.error.errorCode];
            that.offerService.confirmCounterofferErrorSettings.show = true;
          }
        });
  }

  acceptOffer() {
    if (this.topService.loading || this.isTyping || this.offerWasAccepted || this.offerWasRejected) {
      return;
    }
    this.pubSubService.sharedSubject.next({ name: SharedSource.offerAccepted, data: true });
  }

  acceptPreviousOffer() {
    if (this.topService.loading || this.isTyping || this.offerWasAccepted || this.offerWasRejected || this.previousOfferWasAccepted ||
      (this.offerService.selectedOffer.status !== enums.FullOfferStatusEnum.Pending)) {
      return;
    }
    this.pubSubService.sharedSubject.next({ name: SharedSource.previousOfferAccepted, data: this.offerEvent });
  }

  rejectOffer() {
    if (this.topService.loading || this.isTyping || this.offerWasAccepted || this.offerWasRejected) {
      return;
    }

    if (this.offerExpired) {
      this.offerService.offerExpiredSettings.show = true;
      return;
    }
    this.pubSubService.sharedSubject.next({ name: SharedSource.offerRejected, data: this.metadata.isFinal });
  }

  countdownFinished() {
    if (!this.offerExpired) {
      this.offerExpired = true;
      this.offerService.offerExpiredSettings.show = true;
    }
  }

  goToOrderDetails(event: Event, documentId) {
    if (this.topService.isMobile) {
      this.orderService.openPdf(event, documentId);
      return;
    }
    this.routeToOrderDetails.emit(documentId);
  }

  getOrderCreatedMessage() {
    var length = this.offerService.selectedOffer.offerEvents.length;
    var counterofferEvent = this.offerService.selectedOffer.offerEvents[length - 3];

    var offerMadeWasAccepted = counterofferEvent.eventType === enums.OfferEventTypes.OfferMade ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(counterofferEvent.eventType) &&
        [enums.OfferEventTypes.OfferMade].includes(this.offerService.getOfferEventMetadata(counterofferEvent)?.mainEventType));

    var counterofferWasAccepted = [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered].includes(counterofferEvent.eventType) ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(counterofferEvent.eventType) &&
        [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered]
          .includes(this.offerService.getOfferEventMetadata(counterofferEvent)?.mainEventType));

    var counterofferEventGeneratedByBuyer = enums.OfferEventTypes.BuyerCounteroffered === counterofferEvent.eventType ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(counterofferEvent.eventType) &&
        [enums.OfferEventTypes.BuyerCounteroffered].includes(this.offerService.getOfferEventMetadata(counterofferEvent)?.mainEventType));

    var previousOfferEvent = this.offerService.selectedOffer.offerEvents[length - 2].eventType;

    if (this.isBuyer) {
      if (offerMadeWasAccepted) {
        return `Your offer has been accepted, order `;
      }
      if (previousOfferEvent === enums.OfferEventTypes.VendorAcceptedPreviousOffer) {
        return `Your previous offer has been accepted, order`;
      }
      if (previousOfferEvent === enums.OfferEventTypes.BuyerAcceptedPreviousOffer) {
        return `You accepted the previous offer, order`
      }
      if (counterofferWasAccepted) {
        if (!counterofferEventGeneratedByBuyer) {
          return `You accepted the counteroffer, order `;
        }
        return `Your counteroffer has been accepted, order `;
      }

    } else if (this.isVendor) {
      if (offerMadeWasAccepted) {
        return `You accepted the offer, order `;
      }
      if (previousOfferEvent === enums.OfferEventTypes.BuyerAcceptedPreviousOffer) {
        return `Your previous offer has been accepted, order`
      }
      if (previousOfferEvent === enums.OfferEventTypes.VendorAcceptedPreviousOffer) {
        return `You accepted the previous offer, order`
      }
      if (counterofferWasAccepted) {
        if (counterofferEventGeneratedByBuyer) {
          return `You accepted the counteroffer, order `;
        }
        return `Your counteroffer has been accepted, order `;
      }
    }
    return '';
  }

  getOfferExpiredMessage() {
    var length = this.offerService.selectedOffer.offerEvents.length;
    var event = this.offerService.selectedOffer.offerEvents[length - 2];

    var offerMadeWasExpired = event.eventType === enums.OfferEventTypes.OfferMade ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(event.eventType) &&
        [enums.OfferEventTypes.OfferMade].includes(this.offerService.getOfferEventMetadata(event)?.mainEventType));

    var counterofferWasExpired = [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered].includes(event.eventType) ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(event.eventType) &&
        [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered].includes(this.offerService.getOfferEventMetadata(event)?.mainEventType));

    var eventGeneratedByBuyer = enums.OfferEventTypes.BuyerCounteroffered === event.eventType ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(event.eventType) &&
        [enums.OfferEventTypes.BuyerCounteroffered].includes(this.offerService.getOfferEventMetadata(event)?.mainEventType));


    if (this.isBuyer) {
      if (offerMadeWasExpired) {
        return `Your offer has been canceled, seller did not respond in time`;
      }
      if (counterofferWasExpired) {
        if (eventGeneratedByBuyer) {
          return `Your offer has been canceled, seller did not respond in time`;
        }
        return `Your offer has been canceled, you did not respond in time`;
      }

    } else if (this.isVendor) {
      if (offerMadeWasExpired) {
        return `The offer has been canceled, you did not respond in time`;
      }
      if (counterofferWasExpired) {
        if (!eventGeneratedByBuyer) {
          return `The offer has been canceled, buyer did not respond in time`;
        }
        return `The offer has been canceled, you did not respond in time`;
      }
    }
    return '';
  }

  getOfferRejectedMessage() {
    var length = this.offerService.selectedOffer.offerEvents.length;
    var penultimateEvent = this.offerService.selectedOffer.offerEvents[length - 2];

    var offerMadeWasRejected = penultimateEvent.eventType === enums.OfferEventTypes.OfferMade ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(penultimateEvent.eventType) &&
        [enums.OfferEventTypes.OfferMade].includes(this.offerService.getOfferEventMetadata(penultimateEvent)?.mainEventType))


    var counterofferWasRejected = [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered].includes(penultimateEvent.eventType) ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(penultimateEvent.eventType) &&
        [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered].includes(this.offerService.getOfferEventMetadata(penultimateEvent)?.mainEventType))


    var eventGeneratedByBuyer = enums.OfferEventTypes.BuyerCounteroffered === penultimateEvent.eventType ||
      ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(penultimateEvent.eventType) &&
        [enums.OfferEventTypes.BuyerCounteroffered].includes(this.offerService.getOfferEventMetadata(penultimateEvent)?.mainEventType))

    if (this.isBuyer) {
      if (offerMadeWasRejected) {
        return `Your offer has been canceled, you rejected the counteroffer`;
      }
      if (counterofferWasRejected) {
        if (eventGeneratedByBuyer) {
          return `Your offer has been canceled, you rejected the counteroffer`;
        }
        return `Your offer has been canceled, you rejected the counteroffer`;
      }
    } else if (this.isVendor) {
      if (offerMadeWasRejected) {
        return `The offer has been canceled, you rejected the offer`;
      }
      if (counterofferWasRejected) {
        if (!eventGeneratedByBuyer) {
          return `The offer has been canceled, the buyer rejected your counteroffer`;
        }
        return `The offer has been canceled, you rejected the counteroffer`;
      }
    }
    return '';
  }

  getOfferCanceledMessage() {
    var length = this.offerService.selectedOffer.offerEvents.length;
    var offerMadeWasCanceled = this.offerService.selectedOffer.offerEvents[length - 2].eventType === enums.OfferEventTypes.OfferMade;
    var counterofferWasCanceled = [enums.OfferEventTypes.BuyerCounteroffered, enums.OfferEventTypes.VendorCounteroffered].includes(this.offerService.selectedOffer.offerEvents[length - 2].eventType);
    var eventGeneratedByBuyer = enums.OfferEventTypes.BuyerCounteroffered === this.offerService.selectedOffer.offerEvents[length - 2].eventType;

    var cacellationOfferEvent = this.offerService.selectedOffer.offerEvents[this.offerService.selectedOffer.offerEvents.length - 1];
    var reason = JSON.parse(cacellationOfferEvent.metadata, this.sharedService.toCamelCase).reason;

    if (Number(reason) === enums.OfferCancelationType.BuyerDisabled) {
      return this.isBuyer ? 'Your offers have been disabled' : `A party associated with this offer is no longer able to manage offers`;
    }

    if (Number(reason) === enums.OfferCancelationType.VendorDisabled) {
      return this.isVendor ? 'Your offers have been disabled' : `A party associated with this offer is no longer able to manage offers`;
    }

    if (this.isBuyer) {
      return `Your offer has been canceled, the status of the item has changed.`;
    } else if (this.isVendor) {
      return `The offer has been canceled, the status of the item has changed.`;
    }
    return '';
  }

  setUpdatedOfferMessage() {
    this.offerUpdatedMessage = '';

    const newQuantity = this.metadata?.quantity;
    const oldQuantity = this.metadata?.parentEvent?.quantity;
    const newPrice = this.metadata?.price;
    const oldPrice = this.metadata?.parentEvent?.price;

    if (
      !this.metadata?.quantity ||
      !this.metadata?.parentEvent?.quantity ||
      !this.metadata?.price ||
      !this.metadata?.parentEvent?.price
    ) {
      return;
    }

    if (
      (this.offerEvent.eventType === this.EventTypesEnum.BuyerUpdatedOfferEvent && this.isBuyer) ||
      (this.offerEvent.eventType === this.EventTypesEnum.VendorUpdatedOfferEvent && this.isVendor)
    ) {
      if (oldPrice > newPrice && oldQuantity === newQuantity) {
        this.offerUpdatedMessage = ` and ${this.arrowDown} reduced price`
      } else if (oldQuantity > newQuantity && oldPrice === newPrice) {
        this.offerUpdatedMessage = ` and ${this.arrowDown} reduced units`
      } else if (oldPrice < newPrice && oldQuantity === newQuantity) {
        this.offerUpdatedMessage = ` and ${this.arrowUp} increased price`
      } else if (oldQuantity < newQuantity && oldPrice === newPrice) {
        this.offerUpdatedMessage = ` and ${this.arrowUp} increased units`
      } else if (oldPrice > newPrice && oldQuantity > newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowDown} reduced price and units`
      } else if (oldPrice < newPrice && oldQuantity < newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowUp} increased price and units`
      } else if (oldPrice < newPrice && oldQuantity > newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowUp} increased price and ${this.arrowDown} reduced units`
      } else if (oldPrice > newPrice && oldQuantity < newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowDown} reduced price and ${this.arrowUp} increased units`
      }
    }

    if (this.offerEvent.eventType === this.EventTypesEnum.BuyerUpdatedOfferEvent && this.isVendor) {
      if (oldPrice > newPrice && oldQuantity === newQuantity) {
        this.offerUpdatedMessage = ` and ${this.arrowDown} reduced price`
      } else if (oldQuantity > newQuantity && oldPrice === newPrice) {
        this.offerUpdatedMessage = ` and ${this.arrowDown} reduced units`
      } else if (oldPrice < newPrice && oldQuantity === newQuantity) {
        this.offerUpdatedMessage = ` and ${this.arrowUp} increased price`
      } else if (oldQuantity < newQuantity && oldPrice === newPrice) {
        this.offerUpdatedMessage = ` and ${this.arrowUp} increased units`
      } else if (oldPrice > newPrice && oldQuantity > newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowDown} reduced price and units`
      } else if (oldPrice < newPrice && oldQuantity < newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowUp} increased price and units`
      } else if (oldPrice < newPrice && oldQuantity > newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowUp} increased price and ${this.arrowDown} reduced units`
      } else if (oldPrice > newPrice && oldQuantity < newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowDown} reduced price and ${this.arrowUp} increased units`
      }
    }

    if (this.offerEvent.eventType === this.EventTypesEnum.VendorUpdatedOfferEvent && this.isBuyer) {
      if (oldPrice > newPrice && oldQuantity === newQuantity) {
        this.offerUpdatedMessage = ` and ${this.arrowDown} reduced price`
      } else if (oldQuantity > newQuantity && oldPrice === newPrice) {
        this.offerUpdatedMessage = ` and ${this.arrowDown} reduced units`
      } else if (oldPrice < newPrice && oldQuantity === newQuantity) {
        this.offerUpdatedMessage = ` and ${this.arrowUp} increased price`
      } else if (oldQuantity < newQuantity && oldPrice === newPrice) {
        this.offerUpdatedMessage = ` and ${this.arrowUp} increased units`
      } else if (oldPrice > newPrice && oldQuantity > newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowDown} reduced price and units`
      } else if (oldPrice < newPrice && oldQuantity < newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowUp} increased price and units`
      } else if (oldPrice < newPrice && oldQuantity > newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowUp} increased price and ${this.arrowDown} reduced units`
      } else if (oldPrice > newPrice && oldQuantity < newQuantity) {
        this.offerUpdatedMessage = `, ${this.arrowDown} reduced price and ${this.arrowUp} increased units`
      }
    }
  }

  resolvePriceOptions() {
    let qty = this.displayCounterOfferForm || this.isEditOffer ? Number(this.counterOfferForm.value.quantity) : this.metadata.quantity;
    const vendorItemId = this.offerService.selectedOffer.vendorItemId;

    if (this.isBuyer && vendorItemId && qty >= 0 && !isNaN(qty)) {
      this.priceBasedonQty = this.offerService.selectedOffer.effectivePrice;
      this.subscriptions.push(
        this.offerService.getEffectivePrice(vendorItemId, qty)
          .subscribe(
            (effectivePrice) => {
              this.metadata.effectivePrice = effectivePrice;
              this.offerService.selectedOffer.effectivePrice = effectivePrice;
              const newPriceBasedonQty = effectivePrice;
              if (this.priceBasedonQty !== newPriceBasedonQty) {
                this.counterOfferForm.patchValue({ price: null });
                this.priceDropdown.selectedPercent = null;
                this.priceDropdown.selectedValue = null;
                this.priceDropdown.valueWasSelectedByUser = false;
                this.priceBasedonQty = newPriceBasedonQty;
              }

              this.verifyIfDiscountApplied();

              this.priceOptions =
                this.offerService.priceOptions = this.offerService.counterOfferPrices(
                  this.priceBasedonQty,
                  this.isEditOffer && this.offerService.selectedOffer.offerEvents.length === 1 ? true : this.discountApplied,
                  this.isEditOffer ? true : false);

            }));
    } else {
      this.verifyIfDiscountApplied();
    }
  }

  verifyIfDiscountApplied() {
    //verify if discount will apply based on current counter offer data
    this.discountApplied = false;
    if (this.displayCounterOfferForm || this.isEditOffer) {
      let counterofferQty = this.counterOfferForm.value.quantity;
      var length = this.offerService.selectedOffer.offerEvents.length;
      if (this.isEditOffer) {
        length--;
      }
      var currentOfferQty = JSON.parse(this.offerService.selectedOffer.offerEvents[length > 0 ? length - 1 : 0].metadata, this.sharedService.toCamelCase).quantity;
      var currentOfferEffectivePrice = JSON.parse(this.offerService.selectedOffer.offerEvents[length > 0 ? length - 1 : 0].metadata, this.sharedService.toCamelCase).effectivePrice;
      if (counterofferQty !== currentOfferQty) {
        if (currentOfferEffectivePrice !== this.metadata.effectivePrice) {
          this.discountApplied = true;
        }
      }
    }
  }

  getDefaultFormErrors() {
    return {
      quantity: {},
      price: {},
      goodFor: {},
      isFinal: {}
    };
  }

  validCounterofferForm(): boolean {
    if (
      this.isEditOffer &&
      this.counterOfferForm.get('quantity').value === this.metadata.quantity &&
      this.counterOfferForm.get('price').value === this.metadata.price &&
      this.goodForDropdown.selectedValue === this.metadata.goodFor
    ) {
      return false;
    }

    if (this.isEditingOfferMade() && !this.validTotalOffer) {
      return false;
    }

    return !this.counterOfferForm.invalid && !this.topService.loading && this.priceOptions.findIndex(po => po.price === this.priceDropdown.selectedValue) !== -1;
  }


  setUpSubscriptions() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      const control = this.counterOfferForm.get(field);
      if (control) {
        this.subscriptions.push(
          control.valueChanges.subscribe(newValue => {
            if (control && !control.valid) {
              this.formErrors[field] = control.errors;
            } else {
              this.formErrors[field] = {};
            }
          })
        );
      }
    }
  }

  validateQuantity(control: AbstractControl) {
    if (!this.counterOfferForm || !this.item) {
      control.setErrors(null);
      return null;
    }

    if (this.counterOfferError) {
      this.counterOfferError.showed
        ? this.counterOfferError = null
        : this.counterOfferError.showed = true;
    }

    const qty = control.value;
    const minAmountRequired = this.offerService.offersSummary.validationRules.minAmount;
    const price = this.counterOfferForm?.value.price;


    setTimeout(() => { // required to get updated price options list when manually trigger price control validation
      this.resolvePriceOptions();
    }, 0);

    if (price) {
      setTimeout(() => { // required to get updated value when manually trigger price control validation
        this.counterOfferForm.controls['price'].updateValueAndValidity();
      }, 0);
    }

    if (qty && price) {
      this.validTotalOffer = (qty * price < minAmountRequired) ? false : true;
    }

    this.formErrorMsg_Quantity = '';
    if (qty === "") {
      control.setErrors({ validQuantityBasedOnAvailability: false });
      return { validQuantityBasedOnAvailability: false };
    }
    else if (qty > this.offerService.selectedOfferItem?.availability) {
      this.formErrorMsg_Quantity = this.formErrorMsg_QuantityMoreThanAvailability;
      control.setErrors({ validQuantityBasedOnAvailability: false });
      return { validQuantityBasedOnAvailability: false };
    }
    else if (qty <= 0) {
      this.formErrorMsg_Quantity = this.formErrorMsg_QuantityLessOrEqualToZero;
      control.setErrors({ validQuantityBasedOnAvailability: false });
      return { validQuantityBasedOnAvailability: false };
    }
    control.setErrors(null);
    return null;
  }

  validatePrice(control: AbstractControl) {
    if (this.isVendor) {
      return null
    }

    if (!this.counterOfferForm || control.value === null || !this.item) {
      return null;
    }

    var price = parseFloat(control.value.toFixed(4));
    const effectivePrice = this.metadata.effectivePrice;

    const minAmountRequired = this.offerService.offersSummary.validationRules.minAmount;
    const qty = this.counterOfferForm?.value.quantity;
    var minPriceRequired = this.item ? effectivePrice - (this.offerService.offersSummary.validationRules.maxPercentage / 100 * effectivePrice) : null;
    minPriceRequired = parseFloat(minPriceRequired.toFixed(4));
    if (qty && price) {
      this.validTotalOffer = (qty * price < minAmountRequired) ? false : true;
    }

    price = Math.ceil(price * 100) / 100;

    if (minPriceRequired && price < minPriceRequired) {
      return { validPrice: false };
    }

    if (price > effectivePrice) {
      return { validRegardsToMaxPrice: false };
    }

    return null;
  }

  getPercentOff() {
    var percent = parseFloat((this.metadata.price * 100 / this.metadata.effectivePrice).toFixed(2));
    var percentDiscount = 100 - percent;
    return percentDiscount / 100;
  }

  cancelOffer() {
    if (!this.hideResponseButtons || !this.isLastEvent || this.displayCounterOfferForm) {
      return;
    }
    this.pubSubService.sharedSubject.next({ name: SharedSource.offerCanceled, data: true });
  }

  onEditOffer() {
    this.getOfferItemDetail(this.offerService.selectedOffer.vendorItemId)
      .subscribe(item => {
        this.counterOfferForm.reset();
        var oe;
        if (this.offerEvent.id === this.offerService?.lastOwnCounterofferEvent?.id) {
          oe = this.offerService.lastOwnCounterofferEvent;
        } else {
          const index = this.offerService.selectedOffer.offerEvents.findIndex(oe => oe.id === this.offerEvent.id);
          oe = this.offerService.selectedOffer.offerEvents[index];
        }

        this.editOffer.emit(oe);
        this.priceOptions = this.offerService.priceOptions;

        this.offerService.selectedOfferItem.availability = item.availability;
        this.offerService.selectedOfferItem.listPrice = item.listPrice;
        this.offerService.selectedOfferItem.prices = item.prices;

        this.item = item;
        this.isEditOffer = true;
        this.isEditOfferAnimate = true;

        this.counterOfferForm.patchValue({ quantity: this.metadata?.quantity });


        setTimeout(() => {
          const percent = this.priceOptions.find(x => x.price === this.metadata?.price).percent;
          this.counterOfferForm.patchValue({ price: this.metadata?.price });

          this.priceDropdown.selectedValue = this.metadata?.price;
          this.priceDropdown.selectedPercent = percent;
          this.priceDropdown.valueWasSelectedByUser = true;

          this.goodForDropdown.selectedValue = this.metadata?.goodFor;
          this.goodForDropdown.valueWasSelectedByUser = true;
        }, 0);
      });
  }

  setAcceptedDialogActive() {
    this.previousAcceptedIndex = this.offerService.selectedOffer.offerEvents
      .findIndex(oe => [enums.OfferEventTypes.BuyerAcceptedPreviousOffer, enums.OfferEventTypes.VendorAcceptedPreviousOffer].includes(oe.eventType));

    if ((this.hideResponseButtons && !this.isLastEvent && this.isCounterpartyPriorOffer) && this.offerService.confirmPriorAcceptanceSettings.show) {
      return true;
    }
    if ((this.isCounterpartyPriorOffer && this.previousAcceptedIndex >= 0)) {
      return true;
    }
    return false;
  }

  getOfferEventTypeTitle() {
    if ([enums.OfferEventTypes.BuyerAcceptedPreviousOffer, enums.OfferEventTypes.VendorAcceptedPreviousOffer].includes(this.offerEvent.eventType)) {
      const referencedOfferEvent = this.offerService.selectedOffer.offerEvents.find(oe => oe.id === this.metadata.referenceEventId);
      return this.offerEventTypeTitle.get(referencedOfferEvent.eventType);
    }

    if ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(this.offerEvent.eventType)) {
      const index = this.offerService.selectedOffer.offerEvents.findIndex(oe => oe.id === this.offerEvent.id);
      if (index === 0) {
        return this.offerEventTypeTitle.get(enums.OfferEventTypes.OfferMade);
      }
    }

    return this.offerEventTypeTitle.get(this.offerEvent.eventType);
  }

  getOfferItemDetail(itemId: string): Observable<models.OfferItemDetail> {
    if (this.isBuyer) {
      return this.itemsService.getItem(itemId).pipe(
        switchMap((i: IItem) => {
          return of(
            {
              availability: i.inventory.availableToSell,
              listPrice: i.unitPrice,
              prices: i.prices
            } as models.OfferItemDetail
          )
        })
      );
    }
    if (this.isVendor) {
      return this.vendorService.getVendorItemById([itemId]).pipe(
        switchMap((i: any) => {
          return of(
            {
              availability: i.items[0].inventoryInfo.currentInventory.availableToSell,
              listPrice: i.items[0].cost,
              prices: i.items[0].prices
            } as models.OfferItemDetail
          )
        })
      );
    }
  }


  submitUpdateCounterOffer(scope?) {
    var that = this;
    if (scope) {
      that = scope;
    }

    if (!that.validCounterofferForm())
      return;

    if (!that.offerService.confirmUpdateSettings.show) {
      that.offerService.submitUpdateCounterofferReference = {
        fn: that.submitUpdateCounterOffer,
        scope: that
      };
      that.offerService.setPopupsSettings();
      var updatingOfferMade = that.offerEvent.eventType === enums.OfferEventTypes.OfferMade ||
        ([enums.OfferEventTypes.VendorUpdatedOfferEvent, enums.OfferEventTypes.BuyerUpdatedOfferEvent].includes(that.offerEvent.eventType) &&
          that.metadata.mainEventType === enums.OfferEventTypes.OfferMade);

      that.offerService.confirmUpdateSettings.bodyText =
        `Editing this ${updatingOfferMade ? 'offer' : 'counteroffer'} will reset the timer to ${that.goodForDropdown.selectedValue} business day${that.goodForDropdown.selectedValue > 1 ? '(s)' : ''}`;
      that.offerService.confirmUpdateSettings.headerText = `Are you sure you want<br>to update the ${updatingOfferMade ? 'offer' : 'counteroffer'}?`;
      that.offerService.confirmUpdateSettings.show = true;
      return;
    }

    that.isFinalCheckBox = that.offerService.isFinalCounteroffer(that.priceDropdown.selectedValue, that.isBuyer, that.discountApplied);
    var payload = {
      version: that.offerService.selectedOffer.version,
      instanceId: sessionStorage.getItem('sessionId'),
      quantity: that.counterOfferForm.get('quantity').value,
      price: that.counterOfferForm.get('price').value,
      effectivePrice: that.offerService.selectedOffer.effectivePrice,
      isFinal: that.isFinalCheckBox,
      goodFor: that.goodForDropdown.selectedValue
    } as models.OfferEventUpdate;

    that.offerService.updateOfferEvent(payload, that.offerEvent.id)
      .subscribe
      ((res) => {
        that.topService.loading = false;
        that.offerEventEdited = true;
        that.editedOffer.tryEmit(res);
        that.offerService.confirmUpdateSettings.show = false;

        that.isEditOffer = false;
        that.setUpdatedOfferMessage();
        setTimeout(() => {
          that.isEditOfferAnimate = false;
        }, 300);

      },
        (err) => {
          that.topService.loading = false;
          that.offerService.confirmUpdateSettings.show = false;
          if (err.status === that.offerService.OFFER_VALIDATION_ERROR_STATUS_CODE || err.status === that.offerService.PRECONDITION_FAILED) {
            that.offerService.confirmCounterofferErrorSettings.headerText = OfferErrorCodeMap.ErrorCodeMap[err.error.errorCode];
            that.offerService.confirmCounterofferErrorSettings.show = true;
          }
        });
  }

  rejectGoodForDropdownOption(optionValue): boolean {
    if (optionValue != 1 && !this.isEditOffer) {
      return true;
    }

    if (optionValue != 1 &&
      this.isEditOffer &&
      (![enums.OfferEventTypes.OfferMade, enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(this.offerEvent.eventType) ||
        [enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(this.offerEvent.eventType) && this.metadata.mainEventType !== enums.OfferEventTypes.OfferMade)
    ) {
      return true;
    }

    return false;
  }

  isEditingOfferMade(): boolean {
    if (this.isEditOffer) {
      if (this.offerEvent.eventType === enums.OfferEventTypes.OfferMade) {
        return true;
      }

      if ([enums.OfferEventTypes.BuyerUpdatedOfferEvent, enums.OfferEventTypes.VendorUpdatedOfferEvent].includes(this.offerEvent.eventType) &&
        [enums.OfferEventTypes.OfferMade].includes(this.offerService.getOfferEventMetadata(this.offerEvent).mainEventType)) {
        return true;
      }
    }

    return false;
  }

  get chatElementRef(): ElementRef {return this._chatElementRef;} 
}
